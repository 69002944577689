<template>
<div>
    <div style="position: relative;;margin:30px 0 0 60px;z-index:99999">
        <img @click="$router.push('/')" style="cursor: pointer;" src="https://image.youppie.com/pages-logo-light.png"/>
    </div>
  <div class="big-box">
    <div class="clear">
      <div class="left">{{zoneList.subtitle}}</div>
      <div class="right">{{zoneList.datetime}}</div>
    </div>
    <div class="solid"></div>
      <el-image
      v-for="(item,index) in zoneList.content"
      :key="index"
        lazy
        class="banner"
        :src="item"
      ></el-image>
       <div class="solid" style="margin-top:20px"></div>
       <el-backtop target="" :bottom="100" :visibility-height="300"	>
    <div
      style="{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: black;
        padding:4px
      }"
    >
      Top
    </div>
  </el-backtop>
  </div>
  <Foot></Foot>
  </div>
</template>
<script>
var CryptoJS = require("crypto-js");
import Foot from '../components/foot.vue';
export default {
      components:{
    Foot
  },
  data() {
    return {
        zoneList:"",
        // list:[
        //     {src:'http://youppie.doingforce.net/20200331_185650_11185980.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185651_87147627.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185651_82192520.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185651_90624524.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185651_82938786.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185651_79227906.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185652_55183959.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185652_83421855.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185652_50513006.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185652_53085695.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185727_65877736.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185727_86996107.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185727_96966406.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185727_48235958.jpg'},
        //     {src:'http://youppie.doingforce.net/20200331_185728_75934324.jpg'}
        // ]
    };
  },
  created() {
    // console.log(JSON.parse(this.$route.query.list))
      // this.list=JSON.parse(this.$route.query.list).list
      // this.key=JSON.parse(this.$route.query.list).key
    var token = this.Encrypt(JSON.stringify(new Date().getTime()));
    if(JSON.parse(this.$route.query.list).key=='summer'){
      this.$axios({
        url: "https://admin.youppie.com/api/index/get_single2",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      })
      .then((response) => {
        this.zoneList=response.data.data
        // data.list.push(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }else if(JSON.parse(this.$route.query.list).key=='winter'){
      this.$axios({
        url: "https://admin.youppie.com/api/index/get_single1",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      })
      .then((response) => {
        this.zoneList=response.data.data
        // data.list.push(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
    //   this.list=JSON.parse(this.$route.query.list)
  },
  methods:{
    Encrypt(word) {
      var IV = CryptoJS.enc.Utf8.parse("THISISAGOODDAY!!");
      var key = CryptoJS.enc.Utf8.parse("youppie000000000");
      var srcs = CryptoJS.enc.Utf8.parse(word);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.CBC,
        iv: IV,
        padding: CryptoJS.pad.Pkcs7,
      });
      return encrypted.toString();
    },
  }
};
</script>
<style scoped>
.big-box {
  width: 60%;
  margin: 90px auto;
  
}
.solid {
  width: 100%;
  height: 2px;
  background: #ccc;
}
.left {
  float: left;
  line-height: 40px;
  color: #322826;
  font-size: 24px;
  font-family: "Microsoft Yahei", Helvetica, sans-serif;
}
.right {
  float: right;
  line-height: 40px;
}
.banner {
  width: 100%;
  display: block;
}
</style>
